import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import Markdown from 'markdown-to-jsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Box } from '@material-ui/core';
import { parseArrFromString } from '../../../../shared/utility';

const useStyles = makeStyles((theme) => ({
  list: {
    margin: '0 24px 0 24px',
    marginTop: theme.spacing(2),
    width: '500px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      margin: 0
    },
  },
  listNew: {
    margin: '0 24px 0 24px',
    marginTop: theme.spacing(2),
    width: '500px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: 0
    },
  },
  listIcon: {
    minWidth: '36px',
  },
  listTitle: {
    fontSize: '18px',
    fontWeight: '600',
    color: '#31517A',
    borderLeft: '3px solid #1160F7',
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  listTitleNew: {
    fontSize: '48px',
    fontWeight: '700',
    color: '#31517A',
    [theme.breakpoints.down('sm')]: {
      fontSize: '30px',
    },
  },
  listTagNew: {
    fontSize: '18px',
    fontWeight: '700',
    color: '#1160F7',
    fontFamily: 'Inter',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
  listText: {
    fontFamily: 'Poppins, Heebo',
    fontWeight: 400,
    lineHeight: '27px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
  listItem: {
    '&:not(:last-child)': {
      paddingBottom: theme.spacing(2),
    },
    paddingTop: 0,
    color: '#626680',
    lineHeight: '27px',
    backgroundColor: 'transparent !important',
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  listItemText: {
    margin: 0,
  },
}));


const MultiLists = ({ leftList, rightList, listTitles, multiListsWithoutIcon=false, withOutTopMargin }) => {
  const classes = useStyles();
  const listTitle = parseArrFromString(listTitles);
  const contentLeft = parseArrFromString(leftList);
  const contentRight = parseArrFromString(rightList);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const renderLeftList = () => {
    if (contentLeft)
      return (
        <List dense className={withOutTopMargin ? classes.listNew :classes.list}>
         {listTitle.length !== 0 && <Typography align="left" className={classes.listTitle}>
            {listTitle.map((item) => item.left)}
            <br />
          </Typography>}
          {contentLeft.map((item, index) => (
            <ListItem disableGutters classes={{ root: classes.listItem }} key={index}>
             <ListItemText>
             {item.tag &&
              <Typography className={classes.listTagNew}>{item.tag}</Typography>
       }
              {item.title && <div >
              <Typography className={classes.listTitleNew}>{item.title}</Typography>
              </div>}
             </ListItemText>
             { !multiListsWithoutIcon &&<ListItemIcon className={classes.listIcon}>
                <img src="/new/list-icon.svg" alt="List icon" width="21px" height="21px" />
              </ListItemIcon>
  }
              <ListItemText className={classes.listItemText}>
                {typeof item.text == 'string' ? <Markdown children={item.text} /> : item.text}
              </ListItemText>
            </ListItem>
          ))}
        </List>
      );
  };

  const renderRightList = () => {
    if (contentRight)
      return (
        <List dense className={classes.list}>
         {listTitle.length !== 0 && <Typography align="left" className={classes.listTitle}>
            {listTitle.map((item) => item.right)}
            <br />
          </Typography>}
          {contentRight.map((item, index) => (
            <ListItem disableGutters classes={{ root: classes.listItem }} key={index}>
              <Typography>{item.title}</Typography>
             {!multiListsWithoutIcon && <ListItemIcon className={classes.listIcon}>
                <img src="/new/list-icon.svg" alt="List icon" width="21px" height="21px" />
              </ListItemIcon>}
              <ListItemText className={classes.listItemText}>
                {typeof item.text == 'string' ? <Markdown children={item.text} /> : item.text}
              </ListItemText>
            </ListItem>
          ))}
        </List>
      );
  };

  return (
    <>
      <Box display={!isMobile ? "flex" : null} justifyContent="center">
        <Box>{renderLeftList()}</Box>
        <Box>{renderRightList()}</Box>
      </Box>
    </>
  );
};

MultiLists.propTypes = {
    leftList: PropTypes.string,
    rightList: PropTypes.string,
    listTitles: PropTypes.string,
};

export default MultiLists;
