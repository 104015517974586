import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { body1, container, mediumTitle } from '../globalStyles';
import AppStoreIcon from '../../../../public/img/landing/app_store.svg'
import PlayStoreIcon from '../../../../public/img/landing/play_store.svg'
import MockupImage from '../../../../public/img/landing/mobile_app_flying_mockup.png'

const styles = theme => ({
    container,
    mediumTitle,
    body1,
    root: {
        display: 'flex',
        textAlign: 'left',
        paddingTop: '100px',
        paddingBottom: '100px',
        color: '#FFFFFF !important',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundColor: '#FFFFFF !important',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '80px',
            paddingBottom: '80px',
        },
    },
    appContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    textInfo: {
        color: '31517A',
        textAlign: 'left',
        paddingBottom: '20px',
    },
    image: {
        maxWidth: '180px',
        maxHeight: '75px',
        float: 'left',
        paddingRight: '10px',
    },
    firstAppImage: {
        "@media (max-width: 449px)": {
            marginBottom: '30px'
        },
    },
    appstoresLogo: {
        paddingTop: '30px',
    },
    mockup: {
        width: '100%',
    },
    list: {
        color: '#6b708f',
        lineHeight: '1.8',
        paddingLeft: '18px !important',
        marginTop: '0px !important',
    },
});

function DownloadAppVariableSection(props) {
    const { classes } = props;
    return (
        <section className={classes.root}>
            <Grid container spacing={3} className={classes.container + " " + classes.appContainer}>
                <Grid item xs={12} md={7}>
                    <Typography variant="h2" marked="center" className={classes.mediumTitle + " " + classes.textInfo} component="h2">
                        The mobile experience
                    </Typography>
                    <Typography marked="center" className={classes.body1 + " " + classes.textInfo}>
                        The Remitbee Android  and IOS apps now make online money transfer to Philippines easier than ever. Here are some of the added features you can enjoy through our app:
                    </Typography>
                    <ul className={classes.list}>
                        <li>You can make quick money transfers from your phone no matter where you are;</li>
                        <li>Get exchange rate alerts on your phone when rates go up or down;</li>
                        <li>Personalize your favorite currencies so that you only receive notifications for currencies that you use;</li>
                        <li>Biometric authentication protects your money and information;</li>
                        <li>You can also send larger amounts of money, with a daily limit of 9,000.00 CAD and a monthly limit of 50,000.00 CAD.</li>
                    </ul>
                    <Grid container className={classes.appstoresLogo}>
                        <Grid item xs={12} >
                            <a target='_blanc' href="https://apps.apple.com/ca/app/remitbee-money-transfer/id983795500">
                                <img
                                    style={{ marginLeft: '-10px' }}
                                    src={AppStoreIcon}
                                    className={classes.image + " " + classes.firstAppImage}
                                    alt="App store icon"
                                />
                            </a>
                            <a target='_blanc' href="https://play.google.com/store/apps/details?id=com.remitbee.app.app&hl=en_CA">
                                <img
                                    src={PlayStoreIcon}
                                    className={classes.image}
                                    alt="Play store icon"
                                />
                            </a>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={5}>
                    <img
                        src={MockupImage}
                        className={classes.mockup}
                        alt="Remitbee app showcase mockup"
                    />
                </Grid>
            </Grid>
        </section>
    );
}

DownloadAppVariableSection.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DownloadAppVariableSection);