import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { parseArrFromString } from '../../../shared/utility';
import CustomUnderlineMediumHeding from '../components/CustomUnderlinesWebsite/CustomUnderlineMediumHeding';
import { body1, container, mediumTitle } from '../globalStyles';

const styles = (theme) => ({
  container,
  mediumTitle,
  body1,
  root: {
    display: 'flex',
    textAlign: 'left',
    paddingTop: '60px',
    paddingBottom: '60px',
    alignItems: 'flex-start',
    background: 'url("/img/landing/philippines_pickup_bg.png")',
    backgroundSize: 'cover',
    bacgroundPosition: 'center',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      paddingTop: '80px',
      paddingBottom: '80px',
    },
  },
  textInfo: {
    color: '#ffffff',
  },
  whiteBodyText: {
    color: '#ffffff',
  },
  list: {
    color: '#ffffff',
    lineHeight: '1.8',
    '@media (max-width: 959px)': {
      textAlign: 'left',
    },
  },
});

function ImmigrantsForImmigrantsSection(props) {
  const { classes, title1, title2, text, info } = props;
  const item = parseArrFromString(info);
  return (
    <section className={classes.root}>
      <Grid container spacing={3} className={classes.container}>
        <Hidden smDown>
          <Grid item xs={12} md={6}></Grid>
        </Hidden>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h2"
            marked="center"
            className={classes.mediumTitle + ' ' + classes.textInfo}
            component="h2"
          >
            {title1} <br />
            {title2}
          </Typography>
          <CustomUnderlineMediumHeding color={'#ffffff'} />
          <Typography marked="center" className={classes.body1 + ' ' + classes.whiteBodyText}>
            {text}
          </Typography>
          <ul className={classes.list}>
            {item.map((item) => (
              <li>{item.info}</li>
            ))}
          </ul>
        </Grid>
      </Grid>
    </section>
  );
}

ImmigrantsForImmigrantsSection.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ImmigrantsForImmigrantsSection);
